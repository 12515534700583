import React from 'react'
import NumberFormat from 'react-number-format'

export default function WebpayRow({ id, idOrden, idSesion, token, amount, c_date }) {
    return (
        <tr>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{id}</td>
            <td className="px-6 py-4 whitespace-nowrap text-sm  text-gray-500">{idOrden}</td>
            <td className="px-6 py-4 whitespace-nowrap text-sm  text-gray-500 font-medium">{idSesion}</td>
            <td className="px-6 py-4 whitespace-nowrap text-sm  text-gray-500 font-medium truncate block max-w-xs">{token}</td>
            <td className="px-6 py-4 whitespace-nowrap text-sm  text-gray-500 font-medium">{c_date}</td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">{<NumberFormat value={parseInt(amount)} displayType={'text'} thousandSeparator={'.'} decimalSeparator={false} prefix={'$'} />}</td>
        </tr>
    )
}
