import React from 'react'
import NumberFormat from 'react-number-format'

export default function ProductRow({ id, bsaleId, name, units, amount, brandName }) {
    return (
        <tr>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{id}</td>
            <td className="px-6 py-4 whitespace-nowrap text-sm  text-gray-500">{bsaleId}</td>
            <td className="px-6 py-4 whitespace-nowrap text-sm  text-gray-900 font-medium">{name}</td>
            <td className="px-6 py-4 whitespace-nowrap text-sm  text-gray-500 font-medium">{brandName ?? "Genérico"}</td>
            <td className="px-6 py-4 text-sm font-medium items-center text-center">{units}</td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">{<NumberFormat value={parseInt(amount)} displayType={'text'} thousandSeparator={'.'} decimalSeparator={false} prefix={'$'} />}</td>
        </tr>
    )
}
