import { useState, useRef } from 'react'
import { debounce } from 'lodash'
import { toast } from 'react-toastify'
import { Transition } from '@headlessui/react'
import { XCircleIcon } from '@heroicons/react/outline'

export default function SearchComponent( {parentSearchResults, productsTrigger}) {

    const [searchResults, setSearchResults] = useState([])
    const searchInput = useRef(null)
    const notifyError = (message) => toast.error(message)

    const search = debounce(() => {
        if (searchInput.current.value.length < 1) productsTrigger()
        if (searchInput.current.value.length > 3) {
            const urlParams = new URLSearchParams(window.location.search);
            const origen = urlParams.get('origen') ?? 'variants'
            const url = ` ${process.env.REACT_APP_API}/products?origen=${origen}&search=${searchInput.current.value}&limit=25`
            fetch(url).then(response => {
                if (!response.ok) throw new Error('No se encontró el recurso solicitado.')
                return response.json()
            }).then(data => {
                console.log('search results', data)
                parentSearchResults(data)
                //setSearchResults(data)
            }).catch(error =>{
                productsTrigger()
                notifyError(error.message === 'Failed to fetch' ? 'Problemas de conexión al backend' : error.message)
            })
        } else {
            productsTrigger();
            setSearchResults([])
        }
    }, 500)

    const clearSearch = () => {
        searchInput.current.value = ''
        setSearchResults([])
    }

    return (
        <div className="w-full relative">
            <input type="text" placeholder="Buscar por nombre.." ref={searchInput} onKeyUp={search} className="w-full rounded-lg p-3 border-gray-300 border" />
            <Transition show={searchResults.data ? true : false}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <div className="h-full flex items-center absolute right-2 top-0 cursor-pointer hover:text-medicina-verde-500 transition-all" onClick={clearSearch}>
                    <XCircleIcon className="w-8 h-8 text-gray-400" />
                </div>

                <div className="absolute top-12 right-0 w-full bg-white border border-medicina-cafe-200 rounded z-40 h-48 shadow-2xl overflow-y-auto divide-y ">
                    {searchResults.data && searchResults.data.map(product => (
                        <a href={`/products/${product.id}`} className="p-2 block w-full transition-colors cursor-pointer hover:bg-medicina-verde-100">
                            <span className="block text-xs text-gray-400 uppercase">{product.brand ? product.brand.name : "Sin marca"} | {product.parent_id === 0 ? 'Maestro' : 'Variante'}</span>
                            <h1 className="text-medicina-verde-700">{product.name}</h1>
                        </a>
                    ))}
                </div>
            </Transition>
        </div>
    )
}
