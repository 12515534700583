import React from 'react'

export default function SalesWidgetComponent({total = 0, rechazados = 0, completados = 0, recaudado = 0}) {
    /* This example requires Tailwind CSS v2.0+ */
    const stats = [
        { name: 'Ventas completadas', stat: completados },
        { name: 'Ordenes creadas', stat: total },
        { name: 'Ventas rechazadas', stat: rechazados },
        { name: 'Total recaudado', stat: recaudado },
    ]

    return (
        <div>
            <dl className="grid grid-cols-1 gap-5 sm:grid-cols-4">
                {stats.map((item) => (
                    <div key={item.name} className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                        <dt className="text-sm font-medium text-gray-500 truncate">{item.name}</dt>
                        <dd className="mt-1 text-3xl font-semibold text-gray-900">{item.stat}</dd>
                    </div>
                ))}
            </dl>
        </div>
    )
}
