import LayoutComponent from '../../components/Layout/LayoutComponent'

export default function HomePage() {
    
    if (!window.sessionStorage.token ) window.location.href = '/login'

    return (
        <LayoutComponent sectionTitle="Administración LaMedicina" section="home"></LayoutComponent>
    )
}
