import React from 'react'
import NavigationItemComponent from './NavigationItemComponent'
import UserBadgeComponent from '../UserBadgeComponent/UserBadgeComponent'

import {
    HomeIcon,
    ArchiveIcon,
    PresentationChartLineIcon,
    TagIcon,
    HashtagIcon,
    CogIcon,
    CloudDownloadIcon
} from '@heroicons/react/outline'


export default function NavigationComponent({section}) {
    
    const navigation = [
        { name: 'Dashboard', href: '/', icon: HomeIcon, current: section === 'home' },
        { name: 'Productos', href: '/products?origen=variantes', icon: ArchiveIcon, current: section === 'products' },
        { name: 'Bsale', href: '/bsale-products', icon: CloudDownloadIcon, current: section === 'bsale-products' },
        { name: 'Categorías', href: '/categories', icon: TagIcon, current: section === 'categories' },
        { name: 'Etiquetas', href: '/tags', icon: HashtagIcon, current: section === 'tags' },
        { name: 'Ventas', href: '/sales', icon: PresentationChartLineIcon, current: section === 'sales' },
        { name: 'Configuraciones', href: '/settings', icon: CogIcon, current: section === 'settings' },

    ]
    
    return (
        <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">

            <div className="flex-1 flex flex-col min-h-0 bg-medicina-verde-700">
                <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">

                    <div className="flex items-center justify-center flex-shrink-0 px-4">
                        <img className="h-10 w-auto" src="https://cdn.lamedicina.cl/static/img/medicina.svg" alt="La Medicina Tienda de Cultivo" />
                    </div>

                    <nav className="mt-5 flex-1 px-2 space-y-1">
                        {navigation.map(item => <NavigationItemComponent key={`nav-link-${item.name}`} name={item.name} itemHref={item.href} ItemIcon={item.icon} current={item.current} />)}
                    </nav>

                </div>

                <div className="flex-shrink-0 flex border-t border-medicina-verde-600 p-4">
                    <UserBadgeComponent />
                </div>

            </div>
        </div>

      
    )
}
