import React from 'react'

export default function UserBadgeComponent() {

    if (!window.sessionStorage.token ) window.location.href = '/login'

    const logOut = () => {
        window.sessionStorage.removeItem('token')
        window.sessionStorage.removeItem('nombre')
        window.location.href = '/login'
    }

    return (
        <a href="/" className="flex-shrink-0 w-full group block">
            <div className="flex items-center">
                <div>
                    <img
                        className="inline-block h-9 w-9 rounded-full"
                        src={`https://ui-avatars.com/api/?name=${window.sessionStorage.nombre.split(' ')[0]}+${window.sessionStorage.nombre.split(' ')[1]}`}
                        alt={window.sessionStorage.nombre}
                    />
                </div>
                <div className="ml-3">
                    <p className="text-sm font-medium text-medicina-verde-300 ">{window.sessionStorage.getItem("nombre")}</p>
                    <p className="text-xs font-medium text-medicina-verde-50 group-hover:text-red-500" onClick={ () => logOut()}>Cerrar sesión</p>
                </div>
            </div>
        </a>
    )
}
