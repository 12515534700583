import ReactTooltip from 'react-tooltip';
import { QuestionMarkCircleIcon } from '@heroicons/react/outline'


export default function TableComponent({ children, headers }) {

    return (
        <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200 table-fixed">
                            <thead className="bg-medicina-cafe-700">
                                <tr className="text-gray-50 ">
                                    
                                    {headers.map((row)=> {
                                        return (
                                        <th key={'heading-row-'+row.header} scope="col" className={`flexpx-6 py-3 ${row.align ?? 'text-left'} text-xs font-medium uppercase ${ row.width ?? ""}`}>
                                           { row.align &&
                                           <div className="flex justify-center items-center gap-1">
                                                {row.header} {row.tooltip ? <span className="rounded-full block p-1 bg-medicina-cafe-900" data-tip={row.tooltip}><QuestionMarkCircleIcon className="w-4 h-4 text-medicina-cafe-400 hover:text-white transition-colors cursor-help" /></span> : "" }
                                            </div>
                                        }
                                        { !row.align && <span className="flex justify-start px-5 items-center gap-1">{row.header}</span> }
                                        </th>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {children}
                            </tbody>
                        </table>
                        <ReactTooltip />
                    </div>
                </div>
            </div>
        </div>
    )
}
