import { useState, useEffect, useRef } from 'react';
import LayoutComponent from '../../components/Layout/LayoutComponent'
import { ChevronLeftIcon, ChevronRightIcon, SearchIcon } from '@heroicons/react/outline'
import TableComponent from '../../components/TableComponent/TableComponent'
import BsaleProductRowComponent from './BsaleProductRowComponent';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import debounce from 'lodash.debounce';

export default function BsaleProductsPage() {

    const urlParams = new URLSearchParams(window.location.search);
    const pagina = urlParams.get('pagina') ?? 1
    const [currentPage, setCurrentPage] = useState(urlParams.get('pagina') ?? 1)
    const [searchQuery, setSearchQuery] = useState(urlParams.get('search') ?? false)
    const [products, setProducts] = useState(false)
    const [loading, setLoading] = useState(false)
    const currentPageInput = useRef()
    const searchQueryInput = useRef()

    useEffect(() => {

    })

    /** use effect to get products from bsale */
    useEffect(() => {
        if (pagina !== currentPage) window.history.pushState(null, null, `?pagina=${currentPage}`)
        let url = ` ${process.env.REACT_APP_API}/bsale/products?page=${currentPage}`
        if (searchQuery) url += `&search=${searchQuery}`
        fetch(url)
            .then(response => {
                if (!response.ok) throw new Error('No se encontró el recurso solicitado.')
                return response.json()
            })
            .then(data => {
                setProducts(data)
                console.log(data)
                setLoading(false)
            })
            .catch(error => {
                console.log(error)
                setLoading(false)
                setProducts([])
            })
    }, [currentPage, searchQuery, pagina])

    useEffect(() => {
        if (products) {
            currentPageInput.current.value = currentPage
        }
    }, [products, currentPage])

    const headers = [
        { header: "#B", width: "w-1/12" },
        { header: "Nombre" },
        { header: "Actualizado" },
        { header: "I", align: 'text-center', tooltip: "Imagen" },
        { header: "G", align: 'text-center', tooltip: "Galería de imagenes" },
        { header: "D", align: 'text-center', tooltip: "Descripción del producto" },
        { header: "VB", align: 'text-center', tooltip: "Variantes en Bsale" },
        { header: "VL", align: 'text-center', tooltip: "Variantes en local" },
        { header: "Acciones", align: 'text-center' }
    ]

    const changePage = (direction) => {
        setLoading(true)
        if (direction === 'next') {
            setCurrentPage(parseInt(currentPage) + 1)
        } else {
            setCurrentPage(parseInt(currentPage) - 1)
        }
    }

    const updateCurrentPage = debounce(() => {
        setLoading(true)
        let currentPageInputValue = currentPageInput.current.value  //>= 1 ? currentPageInput.current.value : 1
        if (currentPageInputValue >= 1 && Math.floor(products.count / products.limit) >= currentPageInputValue) {
            setCurrentPage(currentPageInputValue)
        } else {
            currentPageInput.current.value = 1
            setCurrentPage(1)
        }
    }, 500)

    const searchProductByName = () => {
        setLoading(true)
        setCurrentPage(1)
        console.log(searchQueryInput.current.value)
        setSearchQuery(searchQueryInput.current.value)
    }



        return (
            <LayoutComponent sectionTitle="Productos en Bsale" section="bsale-products">
                {!products &&
                    <LoadingComponent />
                }
                <div className="w-full grid grid-cols-4 mb-6 gap-6">
                    <div className='bg-white rounded shadow p-4'>
                        <label className="text-medicina-verde-700 text-xs font-bold pb-2 inline-block uppercase">Buscar por nombre</label>
                        <div className='w-full flex gap-x-1'>
                            <input ref={searchQueryInput} type="text" placeholder='"Cookies"' className='text-sm text-gray-700 font-light block w-full rounded p-2 border rounded shadow-inner '  />
                            <button className='bg-medicina-verde-600 hover:bg-medicina-verde-700 text-gray-800 font-bold py-2 px-4 rounded' onClick={searchProductByName}>
                                <SearchIcon className='w-4 h-4 text-white' />
                            </button>
                        </div>
                    </div>


                    <div className='bg-white rounded shadow p-4'>
                        <label className="text-medicina-verde-700 text-xs font-bold pb-2 inline-block uppercase">Buscar por ID Bsale</label>
                        <div className='w-full flex gap-x-1'>
                            <input type="number" min={0} placeholder='4112' className='text-sm text-gray-700 font-light block w-full rounded p-2 border rounded shadow-inner ' />
                            <button className='bg-medicina-verde-600 hover:bg-medicina-verde-700 text-gray-800 font-bold py-2 px-4 rounded'>
                                <SearchIcon className='w-4 h-4 text-white' />
                            </button>
                        </div>
                    </div>

                </div>
                {products.items &&
                    <>
                        <div className={`transition-all ${loading && 'filter blur-lg'}`}>
                            <TableComponent headers={headers}>
                                {products.items.map(product => <BsaleProductRowComponent key={product.id} productId={product.id} productName={product.name} />)}
                            </TableComponent>
                        </div>

                        <div className="w-full flex justify-center items-center space-x-2 py-8">
                            <button className="rounded p-4  bg-white shadow" onClick={() => currentPage > 1 && changePage('previous')}><ChevronLeftIcon className="w-6 h-6" /></button>
                            {/* <button disabled className="rounded p-4 shadow-inner bg-gray-200 text-gray-600 font-black">{currentPage}</button> */}
                            <span className="px-2">
                                <input
                                    disabled={loading}
                                    ref={currentPageInput}
                                    onChange={updateCurrentPage}
                                    className='w-12 text-center ml-2 mr-3 border rounded-md p-2 border-medicina-verde-600'
                                /> de {Math.floor(products.count / products.limit)}
                            </span>
                            <button className="outline-none rounded p-4 bg-white shadow" onClick={() => currentPage < parseInt(products.count / products.limit) && changePage('next')}>Siguiente <ChevronRightIcon className="w-6 h-6 inline-block" /></button>
                        </div>
                    </>
                }
            </LayoutComponent>
        )
    }
