import { Fragment, useEffect, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon, XCircleIcon } from '@heroicons/react/solid'
import LoadingComponent from '../LoadingComponent/LoadingComponent'

export default function BrandSelectorComponent({ inicial = false, productId, relationId, currentBrandName }) {

    const [selected, setSelected] = useState(inicial !== false
        ? inicial[0]
        : { id: 0, name: 'Selecciona una marca' })
    const [currentBrandId, setCurrentBrandId] = useState(inicial)
    const [currentBrandNameText, setCurrentBrandNameText] = useState(currentBrandName)
    const [showLoading, setShowLoading] = useState(false)
    const [state, setState] = useState(false)

    useEffect(() => {
        console.log("brandproductid", relationId)
        if (state === false) {
            const url = `${process.env.REACT_APP_API}/brands`
            return fetch(url)
                .then(res => res.json())
                .then(data => setState(data))
        }
    }, [])

    useEffect(() => {
        if (selected.id !== 0) {
            setShowLoading(true)
            // actualiza la marca del producto
            const url = `${process.env.REACT_APP_API}/products/${productId}/brand`
            fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    product_id: productId,
                    current_brand_id: relationId,
                    brand_id: selected.id
                })
            })
                .then(res => res.json())
                .then(data => {
                    console.log(data)
                    if (data.success) {
                        setCurrentBrandNameText(selected.name)
                        setCurrentBrandId(selected.id)
                    }
                })
                .finally(() => setShowLoading(false))
        }
    }, [selected, productId])


    const removeBrand = () => {
        setSelected({ "id": 97, "name": "SIN MARCA" })
    }

    /** TODO: Falta cargar el valor actual de la categoria */


    return (
        <div className="w-full">
            {showLoading &&
                <LoadingComponent />
            }
            <label htmlFor="bsaleid" className="font-black text-xs uppercase text-medicina-cafe-500 block pl-2 mb-2">Cambiar marca</label>
            {state !== false &&
                <Listbox value={selected} onChange={setSelected}>
                    <div className="relative mt-1 mb-4">
                        <Listbox.Button className="relative border-gray-400 w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg border cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
                            <span className="block truncate">{selected ? selected.name : 'Sin marca/banco'}</span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon
                                    className="w-5 h-5 text-gray-400"
                                    aria-hidden="true"
                                />
                            </span>
                        </Listbox.Button>
                        <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {state.map((item) => (
                                    <Listbox.Option
                                        key={'selector-opcion-' + item.id}
                                        className={({ active }) =>
                                            `${active ? 'text-amber-900 bg-amber-100' : 'text-gray-900'}
                          cursor-default select-none relative py-2 pl-10 pr-4`
                                        }
                                        value={item}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <span
                                                    className={`${selected ? 'font-medium' : 'font-normal'
                                                        } block truncate`}
                                                >
                                                    {item.name}
                                                </span>
                                                {selected ? (
                                                    <span
                                                        className={`${active ? 'text-amber-600' : 'text-amber-600'
                                                            }
                                absolute inset-y-0 left-0 flex items-center pl-3`}
                                                    >
                                                        <CheckIcon className="w-5 h-5" aria-hidden="true" />
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </Listbox>
            }

            <label htmlFor="" className="font-black text-xs uppercase text-medicina-cafe-500 block pl-2 mb-2">Marca</label>
            <div className='flex justify-between items-center'>
                <h3 className='block px-3 uppercase font-bold'>{currentBrandNameText}</h3>
                <XCircleIcon onClick={() => removeBrand() } className='w-4 h-4 hover:text-gray-600 transition-colors text-gray-400 cursor-pointer' />
            </div>
        </div>
    )
}
