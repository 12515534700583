import { useEffect, useState } from 'react';
import { CheckCircleIcon, EyeIcon, PlusIcon, RefreshIcon, XCircleIcon } from '@heroicons/react/outline';

export default function BsaleProductRowComponent({ productId, productName }) {

    const [localCopy, setLocalCopy] = useState(false)
    const [hasImage, setHasImage] = useState(false)
    const [hasVariants, setHasVariants] = useState(false)
    const [bsaleVariants, setBsaleVariants] = useState(false)
    const [trigger, setTrigger] = useState(false)

    /** use effect to get products from local db */
    useEffect(() => {
        const url = `${process.env.REACT_APP_API}/products/${productId}?bsale=true`
        fetch(url)
            .then(response => {
                if (!response.ok) throw new Error('No se encontró el recurso solicitado.')
                return response.json()
            })
            .then(data => {
                if (data.data) return setLocalCopy(data.data)
                else return setLocalCopy([])
            })
            .catch(error => {
                console.log(error)
                return setLocalCopy([])
            })
    }, [productId, trigger])

    useEffect(() => {
        if (localCopy !== false) {
            const url = `${process.env.REACT_APP_API}/products/${productId}/variants?bsale=true`
            fetch(url)
                .then(response => {
                    if (!response.ok) throw new Error('No se encontró el recurso solicitado.')
                    return response.json()
                })
                .then(data => {
                    if (data) return setBsaleVariants(data)
                    return setBsaleVariants([])
                })
                .catch(error => {
                    console.log(error)
                    return setBsaleVariants([])
                })
        }
    }, [localCopy, productId])

    useEffect(() => {
        if (localCopy.id) {
            const url = `${process.env.REACT_APP_API}/products/${localCopy.id}/variants`
            console.log("variante-local url", url)
            fetch(url)
                .then(response => {
                    if (!response.ok) throw new Error('No se encontró el recurso solicitado.')
                    return response.json()
                })
                .then(data => {
                    console.log("variante-local", data)
                    if (data) setHasVariants(data.data)
                    else setHasVariants([])
                })
                .catch(error => {
                    setHasVariants([])
                    console.log(error)
                })
        }
        if (! localCopy.id ) setHasVariants([])
    }, [bsaleVariants, localCopy])

    useEffect(() => {
        if (localCopy && localCopy.attachment) return setHasImage(localCopy.attachment)
        if (localCopy && !localCopy.attachment) return setHasImage([])
    }, [localCopy])

    const refreshProduct = (bsaleId) => {
        console.log(bsaleId)
        setHasVariants(false)
        const url = `${process.env.REACT_APP_API}/bsale/products`
        console.log("refreshProduct url", url)
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 'bsale_id': bsaleId })
        })
        .then(response => {
            if (!response.ok) throw new Error('No se encontró el recurso solicitado.')
            return response.json()
        })
        .then(data => {
            console.log(data);
            setTrigger(!trigger)
        })
        .catch(error => {
            console.log(error)
        })
    }



    return <tr key={'sales-row-' + productId}>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{productId}</td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 ">{productName}</td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 ">{localCopy.id ? localCopy.updated_at : "No ingresado"} </td>

        <td className="px-6 py-4 whitespace-nowrap text-sm">
            {hasImage === false && <div className='flex justify-center'><RefreshIcon className='w-6 h-6 text-gray-300 animate-spin' /></div>}
            {hasImage !== false && hasImage.attachment_id && <div className='flex justify-center'><CheckCircleIcon className='w-6 h-6 text-green-600' /></div>}
            {hasImage !== false && !hasImage.attachment_id && <div className='flex justify-center'><XCircleIcon className='w-6 h-6 text-red-500' /></div>}
        </td>

        <td className="px-6 py-4 whitespace-nowrap text-sm text-red-500"> <div className='flex justify-center'><XCircleIcon className='w-6 h-6' /></div></td>

        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
            {localCopy === false && <div className='flex justify-center'><RefreshIcon className='w-6 h-6 text-gray-300 animate-spin' /></div>}
            {localCopy.description && localCopy.description.length > 0 && <div className='flex justify-center'><CheckCircleIcon className='w-6 h-6 text-green-600' /></div>}
            {localCopy !== false && (!localCopy.description || localCopy.description.length === 0) && <div className='flex justify-center'><XCircleIcon className='w-6 h-6 text-red-500' /></div>}
        </td>


        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-center">
            {bsaleVariants === false && <div className='flex justify-center'><RefreshIcon className='w-6 h-6 text-gray-300 animate-spin' /></div>}
            {bsaleVariants !== false && bsaleVariants.count && <span className='bg-gray-300 rounded-xl px-3 py-1 text-center text-gray-600 font-semibold'>{bsaleVariants.count}</span>}
        </td>

        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-center">
            {hasVariants === false && <div className='flex justify-center'><RefreshIcon className='w-6 h-6 text-gray-300 animate-spin' /></div>}
            {hasVariants !== false && <span className='bg-gray-300 rounded-xl px-3 py-1 text-center text-gray-600 font-semibold'>{hasVariants.length}</span>}
        </td>

        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">

            <div className='flex space-x-2 justify-center'>
                {localCopy === false && <div className='flex justify-center'><RefreshIcon className='w-6 h-6 text-gray-300 animate-spin' /></div>}
                {localCopy !== false && localCopy.id && <button className='rounded bg-blue-600 hover:bg-blue-400 transition-colors text-white p-2' onClick={() => refreshProduct(productId)}><RefreshIcon className='w-4 h-4' /></button>}
                {localCopy !== false && !localCopy.id && <button className='rounded bg-green-600 hover:bg-green-400 transition-colors text-white p-2' onClick={() => refreshProduct(productId)}><PlusIcon className='w-4 h-4' /></button>}
                {localCopy !== false && localCopy.id && <a className='rounded bg-medicina-verde-600 hover:bg-medicina-verde-400 transition-colors text-white p-2' href={`/products/${localCopy.id}`}><EyeIcon className="w-4 h-4" /></a>}
            </div>

        </td>
    </tr>;
}
