import { useEffect, useState } from 'react'
import FileUpload from "../../components/fileUploadComponent/FileUpload"

export default function ImageComponent({productId, parentId, attachmentId, parentAttachmentId, resource}) {
    
    const [image, setImage] = useState("https://cdn.lamedicina.cl/static/img/noimage.png")
    
    const setImageFromChildren = (image) => {
        setImage(image)
    }

    useEffect(() => {
        if (attachmentId) {
            const url = process.env.REACT_APP_API + '/attachment/' + attachmentId
            fetch(url)
            .then(res => res.json())
            .then(data => {
                setImage("https://cdn.lamedicina.cl/"+data.filename)
            })
        } else {
            setImage("https://cdn.lamedicina.cl/static/img/noimage.png")
        }
    }, [])

    
    return (
        <div>
            <img className="w-full h-auto rounded shadow mb-2" src={image} alt="La Medicina" />
            <FileUpload path='imagenes_productos' productId={productId} resource={resource} setImage={setImageFromChildren}/>
        </div>
    )
}