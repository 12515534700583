import React from 'react'

export default function LoadingComponent() {
    return (
        <div className="w-screen h-screen fixed top-0 left-0 bg-medicina-verde-800 opacity-80 z-50">
            <div className="mx-auto h-full flex flex-col items-center justify-center">
                <img src="https://cdn.lamedicina.cl/static/img/Pito.gif" alt="La Medicina" />
                <div className="block font-black uppercase text-gray-200 -mt-6">Cargando contenido...</div>
            </div>
        </div>
    )
}
