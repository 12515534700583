import React from 'react'
import NavigationComponent from './NavigationComponent'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

export default function LayoutComponent({ children, sectionTitle, section, cta = false, ctaLink }) {
    return (
        <div className="bg-gray-100 min-h-screen h-full">
            
            <NavigationComponent section={section}/>
            
            <div className="md:pl-64 flex flex-col flex-1">
                
                {/* ESTE ES EL BOTON RESPONSIVO PARA EL MENU MOBILE 
                    <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100">
                         <button type="button" className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" onClick={() => setSidebarOpen(true)} >
                            <span className="sr-only">Open sidebar</span>
                            <MenuIcon className="h-6 w-6" aria-hidden="true" />
                        </button> 
                    </div> */}

                <main className="flex-1">
                    <div className="pb-6">

                        <div className="mx-auto w-full px-4 sm:px-6 md:px-8 flex items-center justify-between border-gray-300 mb-6 border-b-8 shadow fixed top-0 z-50 pt-6 pb-2 bg-gray-200">
                            <h1 className="text-5xl font-semibold text-medicina-verde-500 medicina-font">{sectionTitle}</h1>
                            {cta && <a href={ctaLink} className="px-3 py-2 text-lg font-semibold bg-medicina-cafe-500 text-white rounded-lg">{cta}</a>}
                        </div>

                        <div className="mx-auto px-4 sm:px-6 md:px-8 pt-24 ">

                            <div className="py-4">
                                {children}
                            </div>
                            
                            <ToastContainer
                                position="bottom-right"
                                autoClose={3000}
                                hideProgressBar={false}
                                closeOnClick={true}
                                pauseOnHover={true}
                                draggable={false}
                                progress={undefined}
                            />
                        </div>
                    </div>
                </main>

            </div>
        </div>
    )
}


