import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { CheckCircleIcon, EyeIcon, PencilIcon, RefreshIcon, SaveIcon, TrashIcon, XCircleIcon } from '@heroicons/react/outline'
import NumberFormat from 'react-number-format'

export default function ProductRowComponent({ product, triggerDeleteProduct }) {

    console.log("PRODUCTO:", product)
    const [editMode, setEditMode] = useState(false)
    const [price, setPrice] = useState([])
    const notifyError = (message) => toast.error(message)
    const notifySuccess = (message) => toast.info(message)
    const updateTitleInput = useRef()
    const [metaData, setMetaData] = useState();


    useEffect(() => {
        console.log(product);
        if (product.parentId !== 0) {
            const url = process.env.REACT_APP_API + '/price/' + product.id
            fetch(url)
                .then(res => res.json())
                .then(res => {
                    setPrice(res)
                })
        }
    }, [])

    const renderCategory = (category, parentCategory) => {
        if (category && category.length > 0) return category.map(c => c.name).join(', ')
        if (parentCategory && parentCategory.length > 0) return parentCategory.join(', ')
    }

    const removeProduct = (id) => {
        const url = `${process.env.REACT_APP_API}/products/${id}`
        fetch(url, { method: 'DELETE', headers: { 'Content-Type': 'application/json' } })
            .then(response => {
                if (!response.ok) throw new Error('No se encontró el recurso solicitado.')
                return true
            }).then(data => {
                notifySuccess("Producto eliminado")
                triggerDeleteProduct(id)
            })
            .catch(error => notifyError(error.message === 'Failed to fetch' ? 'Problemas de conexión al backend' : error.message))
    }

    const updateProduct = (id, name) => {
        const url = `${process.env.REACT_APP_API}/products/${id}`
        fetch(url, { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ name }) })
            .then(response => {
                console.log(response)
                if (!response.ok) return notifyError('No se encontró el recurso solicitado.')
                product.name = name
                notifySuccess("Producto actualizado")
            })
            .catch(error => notifyError(error.message === 'Failed to fetch' ? 'Problemas de conexión al backend' : error.message))
            .finally(() => setEditMode(false))
    }

    return (
        <tr className={`transition-all ${editMode && "bg-medicina-verde-100"} h-full`}>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{product.id}</td>
            <td className="px-6 py-4 whitespace-nowrap text-sm  text-gray-500">{product.bsale_id}</td>
            <td className="px-6 py-4 whitespace-nowrap text-sm  text-gray-900 font-medium">
                {!editMode && <span className="cursor-move" onClick={() => setEditMode(true)}>{product.name}</span>}
                {editMode &&
                    <div className="flex justify-between space-x-3 items-center">
                        <input type="text" defaultValue={product.name} className="border p-2 rounded-lg flex-grow text-xs" ref={updateTitleInput} />
                        <div className="flex-grow-0 flex items-center space-x-2">
                            <button ><SaveIcon onClick={() => updateProduct(product.id, updateTitleInput.current.value)} className="w-6 h-6 text-green-600" /></button>
                            <button onClick={() => setEditMode(false)} ><XCircleIcon className="w-6 h-6 text-red-600" /></button>
                        </div>
                    </div>}
            </td>
            <td className="px-6 py-4 text-sm text-gray-500 w-96">{renderCategory(product.categories, product.__parentCategories)}</td>

            <td className='px-3'>
                <span className="inline-block w-full text-center text-xs p-1  bg-medicina-verde-500 text-medicina-cafe-100 rounded uppercase">
                    {product.brand ? product.brand.name : 'sin marca'}
                </span>
            </td>

            <td className='pl-6'>
                <div className='flex justify-center'>
                    {!product && <RefreshIcon className='w-6 h-6 text-gray-300 animate-spin' />}
                    {product.description && product.description.length > 0 && product.description !== " " && <CheckCircleIcon className='w-6 h-6 text-green-600' />}
                    {(!product.description || product.description.length === 0 || product.description === " ") && <XCircleIcon className='w-6 h-6 text-red-500' />}
                </div>
            </td>

            <td className='px-6'>
                <div className='flex justify-center'>
                    {!product && <RefreshIcon className='w-6 h-6 text-gray-300 animate-spin' />}
                    {product && product.attachment && <CheckCircleIcon className='w-6 h-6 text-green-600' />}
                    {product && !product.attachment && <XCircleIcon className='w-6 h-6 text-red-500' />}
                </div>
            </td>

            <td className='pr-6'>
                <div className='flex justify-center'>
                    <XCircleIcon className='w-6 h-6 text-red-500' />
                    {/* <RefreshIcon className='w-6 h-6 text-gray-300 animate-spin' /> */}
                </div>
            </td>

            <td className="text-sm text-gray-500 text-right">{price.data ? <NumberFormat value={price.data[0].price} displayType={'text'} thousandSeparator={'.'} decimalSeparator={false} prefix={'$'} /> : "Sin precio"}</td>

            <td className="px-6 py-4 whitespace-nowrap flex justify-between text-right text-sm font-medium items-center">

                <a href={`https://dev.lamedicina.cl/producto/${product.slug}-${product.id}`} className="text-blue-600 hover:text-blue-900">
                    <EyeIcon className="w-4 h-4" />
                </a>

                <a href={`/products/${product.id}`} className="text-green-600 hover:text-green-900">
                    <PencilIcon className="w-4 h-4" />
                </a>

                <button onClick={() => removeProduct(product.id)} className="text-red-600 hover:text-red-900">
                    <TrashIcon className="w-4 h-4" />
                </button>

            </td>

        </tr>
    )
}
