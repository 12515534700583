import './App.css';
import { Switch, Route } from "react-router-dom";
import HomePage from './pages/HomePage/HomePage';
import ProductsPage from './pages/ProductPage/ProductsPage';
import ProductPage from './pages/ProductPage/ProductPage';
import SalesPage from './pages/SalesPage/SalesPage';
import SalePage from './pages/SalePage/SalePage';
import BsaleProductsPage from './pages/BsaleProductsPage/BsaleProductsPage';
import LoginPage from './pages/LoginPage/LoginPage';

function App() {

  return (
    <Switch>
  
      <Route exact={true} path="/" component={HomePage} />
      <Route exact={true} path="/products" component={ProductsPage} />
      <Route exact={true} path="/products/:productId" component={ProductPage} />
      <Route exact={true} path="/sales" component={SalesPage} />
      <Route exact={true} path="/sales/:saleId" component={SalePage} />
      <Route exact={true} path="/bsale-products/" component={BsaleProductsPage} />
      <Route exact={true} path="/login" component={LoginPage} />
    </Switch>
  )
}

export default App;
