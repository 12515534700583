import { useEffect, useState, Fragment } from 'react'
import { useParams } from 'react-router-dom'
import LayoutComponent from '../../components/Layout/LayoutComponent'
import TableComponent from '../../components/TableComponent/TableComponent'
import ProductRow from './ProductRow'
import moment from 'moment'
import NumberFormat from 'react-number-format'
import { toast } from 'react-toastify'
import WebpayRow from './WebpayRow'
import { CheckCircleIcon, CodeIcon, DocumentTextIcon, GlobeIcon } from '@heroicons/react/outline'
import { Dialog, Transition } from '@headlessui/react'

export default function SalePage() {

    const [sale, setSale] = useState([])
    const [client, setClient] = useState([])
    const [showModal, setShowModal] = useState(false)

    const notifyError = (message) => toast.error(message)
    const notifySuccess = (message) => toast.info(message)

    const { saleId } = useParams()

    const headers = [
        { header: "#ID", width: "w-1/12" },
        { header: "#B", width: "w-1/12" },
        { header: "Nombre" },
        { header: "Marca", width: "w-1/12" },
        { header: "unidades", width: "w-1/12" },
        { header: "Precio", width: "w-1/12" }
    ]

    const headersWebpay = [
        { header: "#ID", width: "w-1/12" },
        { header: "#ID ORDEN", width: "w-1/12" },
        { header: "ID Sesión" },
        { header: "Token" },
        { header: "Fecha Creación", width: "w-1/12" },
        { header: "Monto", width: "w-1/12" },
    ]

    useEffect(() => {
        if (sale.length === 0) {
            const url = process.env.REACT_APP_API + '/sales/' + saleId
            fetch(url)
                .then(res => res.json())
                .then(data => {
                    console.log("Sale", data)
                    setSale(data)
                })
        }
    }, [sale, saleId])

    useEffect(() => {
        if (sale.id) {
            const url = process.env.REACT_APP_API + '/client-order/' + sale.id
            fetch(url)
                .then(res => res.json())
                .then(data => {
                    console.log(data.client)
                    setClient(data.client)
                })
        }
    }, [sale])

    const generateInvoice = () => {
        const url = `${process.env.REACT_APP_API}/invoice/${sale.id}`
        fetch(url)
            .then(data => data.json())
            .then(res => {
                console.log(res)
                window.location.reload()
            })
    }

    const finalizarVenta = () => {
        const url = `${process.env.REACT_APP_API}/sales/${sale.id}/finalize`
        const body = { ...sale, state: 1 }
        fetch(url)
            .then(data => data.json())
            .then(res => {
                console.log(res)
                window.location.reload()
            })
    }

    const eliminarVenta = () => {
        const url = `${process.env.REACT_APP_API}/sales/${sale.id}/delete`
        fetch(url)
            .then(data => data.json())
            .then(res => {
                console.log(res)
                window.location.href = "/sales"
            })
    }

    const handleRefundClick = () => {
        const url = `${process.env.REACT_APP_API}/refund/${sale.id}`
        fetch(url)
            .then(data => data.json())
            .then(res => {
                console.log(res)
                window.location.reload()
            })
            .catch(err => {
                console.log(err)
                notifyError("No se pudo realizar el reembolso")
            })
    }


    return (
        <LayoutComponent sectionTitle="Detalle orden de compra" section={'sales'}>

            {sale.id &&

                <div className='flex space-x-8'>
                    <div className='flex-grow'>
                        <div className='mb-10'>
                            {sale.refund && <div className="mb-6 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                                <strong className="font-bold">¡Atención!</strong>
                                <span className="block sm:inline"> Esta venta ha sido reembolsada.</span>
                            </div> }

                            <h1 className='font-semibold uppercase text-medicina-verde-600 mb-3'>Orden de compra</h1>
                            <div className='font-mono text-gray-600'>

                                <div className='w-full flex space-x-3 text-sm'>
                                    <div className='font-semibold'>Id orden:</div>
                                    <div>{sale ? sale.id : "--"}</div>
                                </div>

                                <div className='w-full flex space-x-3 text-sm'>
                                    <div className='font-semibold'>Fecha de creación:</div>
                                    <div>{sale ? moment(sale.created_at).format('DD/MM/YYYY HH:mm:ss') : "--"}</div>
                                </div>

                                <div className='w-full flex space-x-3 text-sm'>
                                    <div className='font-semibold'>Fecha de última modificación:</div>
                                    <div>{sale ? moment(sale.updated_at).format('DD/MM/YYYY HH:mm:ss') : "--"}</div>
                                </div>

                                <div className='w-full flex space-x-3 text-sm mt-2'>
                                    <div className='font-semibold'>Monto total:</div>
                                    <div className='font-bold'>{sale ? <NumberFormat value={parseInt(sale.amount)} displayType={'text'} thousandSeparator={'.'} decimalSeparator={false} prefix={'$'} /> : "--"}</div>
                                </div>

                                <div className='w-full flex space-x-3 text-sm text-medicina-verde-700'>
                                    <div className='font-semibold'>Estado del pago:</div>
                                    <div className='font-bold'>{sale && sale.webpay ? sale.webpay.status : "SIN PAGAR"}</div>
                                </div>
                            </div>
                        </div>

                        <div className='mb-10'>
                            <h1 className='font-semibold uppercase text-medicina-verde-600 mb-3'>Detalle cliente</h1>
                            {client && client.name &&
                                <div className='rounded-lg border bg-medicina-verde-100 p-3 mb-8'>
                                    <div className='px-2'>
                                        <table className='table divide-y w-full'>
                                            <tbody>
                                                <tr>
                                                    <td className='w-1/3'>Nombre</td>
                                                    <td className='font-semibold'>{client.name}</td>
                                                </tr>

                                                <tr>
                                                    <td>Teléfono</td>
                                                    <td className='font-semibold'>{client.phone}</td>
                                                </tr>

                                                <tr>
                                                    <td>Dirección</td>
                                                    <td className='font-semibold'>{client.address ?? "Sin dirección."}</td>
                                                </tr>

                                                <tr>
                                                    <td>Región</td>
                                                    <td className='font-semibold'>{client.region !== "" ? client.region : "Sin región."}</td>
                                                </tr>

                                                <tr>
                                                    <td>Ciudad</td>
                                                    <td className='font-semibold'>{client.city ?? "Sin ciudad."}</td>
                                                </tr>

                                                <tr>
                                                    <td>Correo</td>
                                                    <td className='font-semibold'>{client.email}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            }
                        </div>

                        <div className='mb-10'>
                            <h1 className='font-semibold uppercase text-medicina-verde-600 mb-3'>Detalle de la compra</h1>
                            <TableComponent headers={headers}>
                                {sale.products && sale.products.map(
                                    product =>
                                        <ProductRow
                                            key={"table-row-" + product.id}
                                            id={product.id}
                                            name={product.product.name}
                                            units={product.quantity}
                                            amount={product.amount}
                                            bsaleId={product.product.bsale_id}
                                            brandName={product.product.brand_product ? product.product.brand_product.brand.name : "Genérico"} />
                                )}
                            </TableComponent>
                        </div>

                        <div className='mb-10'>
                            <h1 className='font-semibold uppercase text-medicina-verde-600 mb-3'>Transaccion webpay</h1>
                            <TableComponent headers={headersWebpay}>
                                {sale.webpay_transactions && sale.webpay_transactions.map(
                                    row =>
                                        <WebpayRow
                                            key={"table-row-" + row.id}
                                            id={row.id}
                                            idOrden={row.buy_order}
                                            idSesion={row.session_id}
                                            token={row.token}
                                            amount={row.amount}
                                            c_date={moment(row.c_date).format('DD/MM/YYYY HH:mm:ss')} />
                                )}
                            </TableComponent>
                        </div>
                    </div>

                        <div className='flex-grow-0 w-1/3'>
                            {/* <div className="rounded-lg border bg-medicina-verde-100 p-3 mb-8">
                            <label htmlFor="nombre" className="font-black text-xs uppercase text-medicina-cafe-500 block pl-2 mb-2">Historial de la orden</label>
                            <div className='p-4'>
                                <OrderTimeline />
                            </div>
                        </div> */}
                            <div className='rounded-lg border bg-medicina-verde-100 p-3 mb-8'>
                                <label htmlFor="nombre" className="font-black text-xs uppercase text-medicina-cafe-500 block pl-2 mb-2">Editar compra</label>

                                <div className='flex flex-col space-y-2 px-2'>
                                    {sale.webpay && sale.webpay.status === "AUTHORIZED" && !sale.invoice &&
                                        <>
                                            <button className='rounded bg-green-600 text-white font-semibold py-1' onClick={() => setShowModal(true)}>Generar Boleta Bsale</button>
                                            <button className='rounded bg-red-600 text-white font-semibold py-1' onClick={() => handleRefundClick()}>Anular compra</button>
                                        </>
                                    }
                                    {(!sale.webpay || sale.webpay.status !== "AUTHORIZED" || sale.invoice) &&
                                        <>
                                            <button className='rounded cursor-not-allowed bg-gray-400 text-gray-300 font-semibold py-1'>Generar Boleta Bsale</button>
                                            <button className='rounded cursor-not-allowed bg-gray-400 text-gray-300 font-semibold py-1'>Anular compra</button>
                                        </>}
                                    {sale.state === 0 && <button className='rounded bg-yellow-600 text-white font-semibold py-1' onClick={() => eliminarVenta()}>Eliminar orden</button>}
                                    {sale.state === 1 && <button className='rounded bg-gray-400 text-gray-300 font-semibold py-1' disabled>Eliminar orden</button>}
                                </div>
                            </div>

                            <div className='rounded-lg border bg-medicina-verde-100 p-3 mb-8'>
                                <label htmlFor="nombre" className="font-black text-xs uppercase text-medicina-cafe-500 block pl-2 mb-2">Datos de entrega</label>

                                <div className='px-2 pt-3'>
                                    <div className='uppercase text-xs font-light text-medicina-verde-800'>
                                        Tipo de entrega:
                                    </div>
                                    <div className='uppercase font-bold text-medicina-verde-800 text-lg'>
                                        {client && client.address ? "Entrega a domicilio" : "Retiro en tienda"}
                                    </div>
                                </div>

                                <div className='px-2 pt-3'>
                                    <div className='uppercase text-xs font-light text-medicina-verde-800'>
                                        Rut Cliente:
                                    </div>
                                    <div className='uppercase font-bold text-medicina-verde-800 text-lg'>
                                        {client ? client.rut : "--"}
                                    </div>
                                    <div className='pt-8 pb-2'>

                                        {sale.state === 0 && sale.webpay && sale.webpay.status === "AUTHORIZED" &&
                                            <button onClick={() => finalizarVenta()} className='rounded bg-medicina-verde-500 text-white font-semibold py-1 w-full text-center'>
                                                {client && client.address ? "Marcar como entregado" : "Marcar como preparado"}
                                            </button>
                                        }

                                        {sale.state === 1 && <div className='rounded flex justify-center space-x-5  bg-green-500 text-white font-semibold py-1 w-full text-center'>Pedido finalizado <CheckCircleIcon className='w-6 h-6' /></div>}

                                        {(!sale.webpay || sale.webpay.status !== "AUTHORIZED") && <button className='rounded w-full cursor-not-allowed bg-gray-400 text-gray-300 font-semibold py-1'>Marcar como entregado</button>}
                                    </div>
                                </div>
                            </div>

                            <div className='rounded-lg border bg-medicina-verde-100 p-3 mb-8'>
                                {!sale.invoice && <label htmlFor="nombre" className="font-black text-xs uppercase text-medicina-cafe-500 block pl-2 mb-2">Sin Boleta electrónica</label>}
                                {sale.invoice && <>
                                    <label htmlFor="nombre" className="font-black text-xs uppercase text-medicina-cafe-500 block pl-2 mb-2">Timbre Boleta electrónica</label>
                                    <div className='p-3'>
                                        <img src={sale.invoice.sii_stamp} alt="Timbre electrónico SII" className='w-full mb-5' />
                                        <div className="w-full flex flex-col space-y-3">
                                            <a href={sale.invoice.pdf} className='hover:bg-medicina-verde-500 transition-colors bg-medicina-verde-600 text-xl w-full font-semibold text-medicina-verde-50 rounded-xl py-2 px-3 flex'>
                                                <div className='flex-grow-0 px-2'>
                                                    <DocumentTextIcon className='w-6 h-6' />
                                                </div>
                                                <span className="flex-grow">Boleta PDF</span>
                                            </a>

                                            <a href={sale.invoice.public_url} className='hover:bg-medicina-verde-500 transition-colors bg-medicina-verde-600 text-xl w-full font-semibold text-medicina-verde-50 rounded-xl py-2 px-3 flex'>
                                                <div className='flex-grow-0 px-2'>
                                                    <GlobeIcon className='w-6 h-6' />
                                                </div>
                                                <span className="flex-grow">Boleta Bsale</span>
                                            </a>

                                            <a href={sale.invoice.xml_url} className='hover:bg-medicina-verde-500 transition-colors bg-medicina-verde-600 text-xl w-full font-semibold text-medicina-verde-50 rounded-xl py-2 px-3 flex'>
                                                <div className='flex-grow-0 px-2'>
                                                    <CodeIcon className='w-6 h-6' />
                                                </div>
                                                <span className="flex-grow">Xml</span>
                                            </a>
                                        </div>
                                    </div>

                                </>
                                }
                            </div>

                        </div>
                    </div>
            }



                    <Transition appear show={showModal} as={Fragment}>
                        <Dialog
                            as="div"
                            className="fixed inset-0 z-10 overflow-y-auto "
                            onClose={() => setShowModal(false)}
                        >
                            <div className="min-h-screen px-4 text-center ">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-50"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-50"
                                    leaveTo="opacity-0"
                                >
                                    <Dialog.Overlay className="bg-medicina-verde-900 fixed inset-0" />
                                </Transition.Child>

                                {/* This element is to trick the browser into centering the modal contents. */}
                                <span
                                    className="inline-block h-screen align-middle "
                                    aria-hidden="true"
                                >
                                    &#8203;
                                </span>
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg font-medium leading-6 text-gray-900"
                                        >
                                            Esta seguro de generar una boleta?
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                Esta acción generara una boleta electronica a través de la platafoma bslae, notificando al cliente que su compra ha sido validada y se encuentra
                                                en preparación. Ademas de ésto, se descontará stock desde bsale y se enviará una copia de la boleta al cliente.
                                            </p>
                                        </div>

                                        <div className="mt-4 space-x-2 flex">
                                            <button
                                                type="button"
                                                className=" justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                                onClick={() => generateInvoice()}
                                            >
                                                Entendido, generar boleta
                                            </button>

                                            <button
                                                type="button"
                                                className=" justify-center px-4 py-2 text-sm font-medium text-red-900 bg-red-100 border border-transparent rounded-md hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
                                                onClick={() => setShowModal(false)}
                                            >
                                                Quiero revisar los datos
                                            </button>
                                        </div>
                                    </div>
                                </Transition.Child>
                            </div>
                        </Dialog>
                    </Transition>

                </LayoutComponent>
    )
}
