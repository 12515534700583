import { Fragment, useEffect, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'

export default function SelectorDeCategorias({ setCategoria, categoria, inicial = false, productId }) {

    const [selected, setSelected] = useState(inicial !== false
        ? inicial
        : { id: 0, name: 'Seleccionar categoría' }
    )
    const [state, setState] = useState(false)

    useEffect(() => {
        if (state === false) {
            const url = `${process.env.REACT_APP_API}/categories`
            return fetch(url)
                .then(res => res.json())
                .then(data => setState(data))
        }
    }, [])


    const appendCategory = (cat) => {
        const url = `${process.env.REACT_APP_API}/category/product`
        const data = {
            category_id: cat.id,
            product_id: productId
        }
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => {
                console.log("store response: ", data)
                window.location.reload()
            })
    }

    return (
        <div className="w-full">
            <label htmlFor="bsaleid" className="font-black text-xs uppercase text-medicina-cafe-500 block pl-2 mb-2">Categoría(s)</label>

            {state !== false &&
                <Listbox value={selected} onChange={appendCategory}>
                    <div className="relative mt-1 mb-4">
                        <Listbox.Button className="relative border-gray-400 w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg border cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
                            <span className="block truncate">{selected.name}</span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon
                                    className="w-5 h-5 text-gray-400"
                                    aria-hidden="true"
                                />
                            </span>
                        </Listbox.Button>
                        <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm divide-y">
                                {state.map((item) => (
                                    <div key={'selector-opcion-' + item.id}>
                                        <Listbox.Option className={({ active }) => `${active ? 'text-amber-900 bg-amber-100' : 'text-gray-900'}  cursor-default select-none relative py-2 pl-10 pr-4`} value={item} >
                                            {({ selected, active }) => (
                                                <>
                                                    <span className={`${selected ? 'font-medium' : 'font-normal'} block truncate`} >{item.name}</span>
                                                    {selected
                                                        ? (<span className={`${active ? 'text-amber-600' : 'text-amber-600'} absolute inset-y-0 left-0 flex items-center pl-3`}>
                                                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                                                        </span>)
                                                        : null}
                                                </>
                                            )}
                                        </Listbox.Option>

                                        {item.children && item.children.map((child) => (
                                            <div key={'selector-opcion-' + child.id} >
                                                <Listbox.Option className={({ active }) => `${active ? 'text-amber-900 bg-amber-100' : 'text-gray-900'}  cursor-default select-none relative py-2 pl-14 pr-4`} value={child} >
                                                    {({ selected, active }) => (
                                                        <>
                                                            <span className={`${selected ? 'font-medium' : 'font-normal'} block truncate`} >{child.name}</span>
                                                            {selected
                                                                ? (<span className={`${active ? 'text-amber-600' : 'text-amber-600'} absolute inset-y-0 left-0 flex items-center pl-3`}>
                                                                    <CheckIcon className="w-5 h-5" aria-hidden="true" />
                                                                </span>)
                                                                : null}
                                                        </>
                                                    )}
                                                </Listbox.Option>

                                                {child.children && child.children.map((grandchild) => (

                                                    <Listbox.Option key={'selector-opcion-' + grandchild.id} className={({ active }) => `${active ? 'text-amber-900 bg-amber-100' : 'text-gray-900'}  cursor-default select-none relative py-2 pl-20 pr-4`} value={grandchild} >
                                                        {({ selected, active }) => (
                                                            <>
                                                                <span className={`${selected ? 'font-medium' : 'font-normal'} block truncate`} >{grandchild.name}</span>
                                                                {selected
                                                                    ? (<span className={`${active ? 'text-amber-600' : 'text-amber-600'} absolute inset-y-0 left-0 flex items-center pl-3`}>
                                                                        <CheckIcon className="w-5 h-5" aria-hidden="true" />
                                                                    </span>)
                                                                    : null}
                                                            </>
                                                        )}
                                                    </Listbox.Option>
                                                ))}
                                            </div>

                                        ))}

                                    </div>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </Listbox>
            }
        </div>
    )
}
