import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import LayoutComponent from '../../components/Layout/LayoutComponent'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import TableComponent from '../../components/TableComponent/TableComponent'
import ProductRowComponent from './ProductRowComponent'

import SearchComponent from '../../components/SearchComponent/SearchComponent'
export default function ProductsPage() {

    const urlParams = new URLSearchParams(window.location.search);
    const pagina = urlParams.get('pagina') ?? 1

    if (!urlParams.get('origen')) window.location.href = "/products?pagina=1&origen=variantes"

    const [products, setProducts] = useState([])
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(pagina)
    const [deleted, setDeleted] = useState(0)
    const [trigger, setTrigger] = useState(false)

    const notifyError = (message) => toast.error(message)
    const notifySuccess = (message) => toast.info(message)

    const triggerDeleteProduct = (id) => {
        setDeleted(id)
    }

    const setSearchResults = (results) => {
       console.log(results)
        setProducts(results)
    }

    const productsTrigger = () => { 
        console.log('productsTrigger')
        setTrigger(!trigger) }

    const headers = [
        { header: "#ID" },
        { header: "#B" },
        { header: "Nombre" },
        { header: "Categorias" },
        { header: "Marca", width: "w-1/12" },
        { header: "D", align: 'text-center', tooltip: "Descripción del producto" },
        { header: "I", align: 'text-center', tooltip: "Imagen" },
        { header: "G", align: 'text-center', tooltip: "Galería de imagenes" },
        { header: "Precio", align: 'text-center' },
        { header: "Acciones", width: "w-1/12" }
    ]

    const setActiveTab = (tab) => {
        const nonSelectedStyles = "inline-block mr-2 bg-gray-300 text-gray-500 hover:text-medicina-verde-500 shadow-inner text-sm px-3 py-1 rounded-xl uppercase font-semibold"
        const selectedStyles = "inline-block mr-2 bg-medicina-verde-500 text-white text-sm px-3 py-1 rounded-xl uppercase font-bold"
        if (tab === urlParams.get('origen')) return selectedStyles
        return nonSelectedStyles
    }

    const changePage = (direction) => {
        setLoading(true)
        if (direction === 'next') {
            setCurrentPage(parseInt(currentPage) + 1)
        } else {
            setCurrentPage(parseInt(currentPage) - 1)
        }
    }

    useEffect(() => {
        console.log('reload products')
        const urlParams = new URLSearchParams(window.location.search);
        const origen = urlParams.get('origen')
        const pagina = urlParams.get('pagina') ?? 1
        if (pagina !== currentPage) window.history.pushState(null, null, `?origen=${origen}&pagina=${currentPage}`)
        const origenes = ['variantes', 'masters', 'deleted']
        const url = ` ${process.env.REACT_APP_API}/products?origen=${origenes.indexOf(origen) === -1 ? 'variantes' : origen}&page=${currentPage}`
        fetch(url)
            .then(response => {
                if (!response.ok) throw new Error('No se encontró el recurso solicitado.')
                return response.json()
            })
            .then(data => {
                setProducts(data)
                setLoading(false)
            })
            .catch(error => notifyError(error.message === 'Failed to fetch' ? 'Problemas de conexión al backend' : error.message))
    }, [currentPage, deleted, trigger])


    return (
        <LayoutComponent sectionTitle="Productos" section="products" ctaLink="bsale-products" cta="Agregar producto">
            {!products.data &&
                <LoadingComponent />
            }
            {products.data &&
                <>
                    <div className="w-full py-6 flex items-center">
                        <div className="w-1/2">
                            <a href="/products?origen=variantes" className={setActiveTab('variantes')}>
                                Variantes
                            </a>

                            <a href="/products?origen=masters" className={setActiveTab('masters')}>
                                Maestros
                            </a>

                            <a href="/products?origen=deleted" className={setActiveTab('deleted')}>
                                Desactivados
                            </a>
                        </div>
                        <div className="flex-grow">
                            <SearchComponent parentSearchResults={setSearchResults} productsTrigger={productsTrigger} />
                        </div>
                    </div>

                    <div className={`transition-all ${loading && 'filter blur-lg'}`}>
                        <TableComponent headers={headers}>
                            {products.data.length > 0 && products.data.map((product) => (
                                <ProductRowComponent product={product} key={product.id} triggerDeleteProduct={triggerDeleteProduct} />
                            ))}
                        </TableComponent>
                    </div>

                    <div className="w-full flex justify-center items-center space-x-2 py-8">
                        <button className="rounded p-4  bg-white shadow" onClick={() => currentPage > 1 && changePage('previous')}><ChevronLeftIcon className="w-6 h-6" /></button>
                        <button disabled className="rounded p-4 shadow-inner bg-gray-200 text-gray-600 font-black">{products.meta.current_page}</button>
                        <span className="px-2">de {products.meta.last_page}</span>
                        <button className="outline-none rounded p-4 bg-white shadow" onClick={() => currentPage < products.meta.last_page && changePage('next')}>Siguiente <ChevronRightIcon className="w-6 h-6 inline-block" /></button>
                    </div>

                </>}
        </LayoutComponent>
    )
}