import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import LayoutComponent from '../../components/Layout/LayoutComponent'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import ProductTextEditor from './ProductTextEditor'
import SelectorDeCategorias from './SelectorDeCategorias'
import Categorias from './Categorias'
import ImageComponent from './ImageComponent'
import { SaveIcon } from '@heroicons/react/outline'
import { ArrowRightIcon } from '@heroicons/react/solid'
import BrandSelectorComponent from '../../components/BrandSelectorComponent/BrandSelectorComponent'

export default function ProductPage() {

    const [state, setState] = useState({})
    const [editorData, setEditorData] = useState()
    const [editor, setEditor] = useState()
    const [categories, setCategories] = useState()

    const [parent, setParent] = useState(false)
    const [siblings, setSiblings] = useState([])

    const { productId } = useParams()
    const nameInput = useRef(null)

    useEffect(() => {
        if (state.data && state.data.parent_id !== 0) {
            const url = process.env.REACT_APP_API + '/products/' + state.data.parent_id
            fetch(url)
                .then(res => res.json())
                .then(data => {
                    setParent(data.data)
                })
        }
    }, [state])

    useEffect(() => {
        if (productId <= 0 || isNaN(productId)) return window.location.href = '/products'
        const url = process.env.REACT_APP_API + '/products/' + productId
        fetch(url)
            .then(res => res.json())
            .then(data => {
                console.log(data)
                setState(data)
            })
    }, [productId])

    useEffect(() => {
        if (state.data ) {
            // const url = process.env.REACT_APP_API + '/siblings/' + state.data.parent_id
            const url = state.data.parent_id !== 0 
                ? process.env.REACT_APP_API + '/products/' + state.data.parent_id +'/variants'
                : process.env.REACT_APP_API + '/products/' + state.data.id + '/variants'

                console.log("aqui", url)
                
            fetch(url)
                .then(res => res.json())
                .then(data => {
                    setSiblings(data)
                })
        }
    }, [state])

    const updateProduct = () => {
        const data = {
            id: state.data.id,
            name: nameInput.current.value,
            description: editorData,
        }
        console.log(data)
        const url = process.env.REACT_APP_API + '/products/' + state.data.id
        fetch(url, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => {
                window.location.reload()
            })
    }

    return (
        <LayoutComponent sectionTitle="Editar Producto" section={"products"}>
            {!state.data &&
                <LoadingComponent />
            }
            {state.data &&
                <div className="w-full flex items-stretch space-x-7">
                    <div className="flex-grow w-full">

                        <div className="w-full block mb-8">
                            <label htmlFor="nombre" className="font-black text-xs uppercase text-medicina-cafe-500 block pl-2 mb-2">Nombre</label>
                            <input ref={nameInput} type="text" name="name"
                                className="w-full block mx-0 text-xl border font-bold text-gray-500 focus:text-gray-700 focus:outline-none transition focus:ring-2 focus:ring-medicina-verde-200 rounded py-2 px-4"
                                defaultValue={state.data.name} />
                        </div>


                        {parent &&
                            <div className="block mb-8">
                                <label htmlFor="description" className="font-black text-xs uppercase text-medicina-cafe-500 block pl-2 mb-2">Descripción maestra</label>
                                <div className='px-2 text-gray-400'>
                                    <div dangerouslySetInnerHTML={{ __html: parent.description }} />
                                    <small className='block mt-3'>
                                        Para editar esta descripción <a href={`/products/${parent.id}`} className='underline hover:text-medicina-verde-500'>edita el producto maestro</a>
                                    </small>
                                </div>
                            </div>
                        }
                        <div className="block mb-3">
                            <label htmlFor="description" className="font-black text-xs uppercase text-medicina-cafe-500 block pl-2 mb-2">Descripción</label>
                            <ProductTextEditor setEditor={setEditor} setEditorData={setEditorData} text={state.data.description} />
                        </div>

                        <div className='block mb-8'>
                            <button onClick={updateProduct} className='flex items-center px-4 py-1 bg-green-500 text-white font-semibold rounded-lg'><SaveIcon className='w-5 h-5 mr-2' />Guardar detalle</button>
                        </div>
                    </div>

                    <div className="md:w-3/6 xl:w-2/6 gap-3 flex flex-col">
                        {parent &&
                            <div className='border rounded p-3 bg-medicina-verde-50 mt-4'>
                                <label htmlFor="" className="font-black text-xs uppercase text-medicina-cafe-500 block pl-2 mb-2">Producto Maestro</label>
                                <div className='px-2 w-full '>
                                    <a href={'/products/' + parent.id} className='p-1 rounded underline flex hover:bg-medicina-verde-400  hover:text-medicina-verde-100 flex items-center justify-between gap-2'>
                                        <span className='inline-block '>{parent.name}</span>
                                        <ArrowRightIcon className='w-5 h-5 inline-block transform -rotate-45' />    
                                    </a>
                                </div>
                            </div>
                        }

                        <div className="block border rounded p-3 bg-medicina-verde-50">
                            <label htmlFor="bsaleid" className="font-black text-xs uppercase text-medicina-cafe-500 block pl-2 mb-2">Imagend destacada</label>
                            <ImageComponent productId={state.data.id} parentId={state.data.parent_id} attachmentId={state.data.attachment ? state.data.attachment.attachment_id : false} resource="product" />
                        </div>

                        <div className="border rounded p-3 bg-medicina-verde-50">
                            <div className="block">
                                <SelectorDeCategorias productId={state.data.id} setCategoria={setCategories} categoria={categories} inicial={false} />
                            </div>

                            <div className="block">
                                <Categorias categorias={state.data.categories.length > 0 ? state.data.categories : state.data.parent ? state.data.parent.categories : false} />
                            </div>
                        </div>

                        {state.data.parent_id != 0 &&
                            <div className='border rounded p-3 bg-medicina-verde-50'>
                                <label htmlFor="" className="font-black text-xs uppercase text-medicina-cafe-500 block pl-2 mb-2">Marca</label>
                                <h3 className='block px-3 uppercase font-bold'>{state.data.brand ? state.data.brand.name : 'Sin marca'}</h3>
                                <small className='px-3 block mt-3'>
                                    Para cambiar la marca <a href={`/products/${state.data.parent_id}`} className='underline hover:text-medicina-verde-500'>edita el producto maestro</a>
                                </small>
                            </div>
                        }

                        {state.data.parent_id === 0 &&
                            <div className='border rounded p-3 bg-medicina-verde-50'>
                                <BrandSelectorComponent productId={state.data.id} currentBrandName={state.data.brand ? state.data.brand.name : false } relationId={state.data.brand_product ? state.data.brand_product.id : false} />
                            </div>
                        }

                        {siblings.data &&
                            <div className='border rounded p-3 bg-medicina-verde-50'>
                                <label htmlFor="" className="font-black text-xs uppercase text-medicina-cafe-500 block pl-2 mb-2">{ state.data.parent_id === 0 ? "Variantes" :" Productos relacionados" }</label>
                                <div className='px-2'>
                                    {siblings.data.map(sibling => <a key={`sibling-${sibling.id}`} href={'/products/' + sibling.id} className='block underline hover:text-medicina-verde-500'>{sibling.name}</a>)}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }
        </LayoutComponent >
    )
}
