import React from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default function ProductTextEditor({ setEditor, setEditorData, text }) {
    return (
        <>
            <CKEditor
                editor={ClassicEditor}
                data={text}
                onReady={editor => {
                    setEditor(editor)
                    setEditorData(text)
                }}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    setEditorData(data)
                    if (data === "") {
                        setEditorData(" ")
                    }

                }}
                onBlur={(event, editor) => {
                    setEditorData(editor.getData())
                    if (editor.getData() === "") {
                        setEditorData(" ")
                    }

                }}
                onFocus={(event, editor) => {
                    setEditorData(editor.getData())
                    if (editor.getData() === "") {
                        setEditorData(" ")
                    }
                }}
            />
        </>
    )
}
