import { XCircleIcon } from '@heroicons/react/solid'


export default function Categorias({ categorias }) {

    const quitarCategoria = async (product,cat) => {
        const url = process.env.REACT_APP_API + '/category/product/'+product+'/'+cat
        const res = await fetch(url, { method: 'DELETE'})
        window.location.reload()
    }
    return (
        <div className="bg-gray-200 rounded shadow-inner">
            <div className="text-xs font-semibold uppercase text-gray-500">
                {categorias &&
                    categorias.map(categoria => {
                        return <div onClick={ () => quitarCategoria(categoria.product_id, categoria.category_id) } key={"categoria-producto-"+categoria.id} className="w-full transition-colors flex justify-between items-center p-2 cursor-pointer hover:bg-medicina-verde-500 hover:text-white">
                                    <div>{categoria.category.name}</div>
                                    <div><XCircleIcon className="w-4 h-4" /></div>
                                </div>
                    })
                }
            </div>
        </div>
    )
}
