import { useRef, useState } from 'react'

export default function LoginPage() {

    const [error, setError] = useState(false)

    const emailRef = useRef()
    const passwordRef = useRef()

    const handleClick = (e) => {
        e.preventDefault()
        console.log('clicked')
        const url = process.env.REACT_APP_API + '/user';
        console.log(url)
        const data = {
            email: emailRef.current.value,
            password: passwordRef.current.value
        }
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => {
                console.log("usuario", data)
                if (data.status === 'error') {
                    setError(true)
                } else {
                    window.sessionStorage.setItem('token', data.token);
                    window.sessionStorage.setItem('nombre', data.usuario);
                    window.location.href = '/';
                }
            }).catch(err => {
                console.log(err)
                setError(true)
            })
    }

    return (
        <>
            <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <img
                        className="mx-auto h-auto w-40"
                        src="https://blog.lamedicina.cl/wp-content/uploads/2022/05/cropped-LOGO-VECTORES-COLORES-11.png"
                        alt="La Medicina Tienda de Cultivo"
                    />
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-medicina-verde-900">Iniciar sesión</h2>
                    <p className="mt-2 text-center text-sm text-medicina-cafe-600">
                        Inicia sesión con tu cuenta para acceder a la aplicación.
                    </p>
                    {error && (
                        <div className="mt-4 text-center text-sm text-red-600">
                            <p>Usuario o contraseña incorrectos</p>
                        </div>
                    )}
                </div>

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        <form className="space-y-6" action="#" method="POST">
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                    Usuario
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="email"
                                        ref={emailRef}
                                        name="email"
                                        type="text"
                                        autoComplete="usuario"
                                        required
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-medicina-verde-500 focus:border-medicina-verde-500 sm:text-sm"
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                    Contraseña
                                </label>
                                <div className="mt-1">
                                    <input
                                        ref={passwordRef}
                                        id="password"
                                        name="password"
                                        type="password"
                                        autoComplete="current-password"
                                        required
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-medicina-verde-500 focus:border-medicina-verde-500 sm:text-sm"
                                    />
                                </div>
                            </div>

                            <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                    {/* <input
                                        id="remember-me"
                                        name="remember-me"
                                        type="checkbox"
                                        className="h-4 w-4 text-medicina-verde-600 focus:ring-medicina-verde-500 border-gray-300 rounded"
                                    />
                                    <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                                        Recuerdame
                                    </label> */}
                                </div>

                                {/* <div className="text-sm">
                                    <a href="#" className="font-medium text-medicina-verde-600 hover:text-medicina-verde-500">
                                        Olvidaste tu contraseña?
                                    </a>
                                </div> */}
                            </div>

                            <div>
                                <button
                                    onClick={handleClick}
                                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-medicina-verde-600 hover:bg-medicina-verde-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    Iniciar sesón
                                </button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </>
    )
}
