import React from 'react'

export default function NavigationItemComponent({item, name, current = false, itemHref, ItemIcon}) {
    return (
        <a key={name} href={itemHref} className={`roup flex items-center px-2 py-2 text-sm font-medium rounded-md ${current ? 'bg-medicina-verde-900 text-white' : 'text-medicina-verde-300 hover:bg-gray-50 hover:text-medicina-verde-900'}`} >
            <ItemIcon className={`mr-3 flex-shrink-0 h-6 w-6 ${current ? 'text-white' : 'text-medicina-verde-400 group-hover:text-medicina-verde-900'}`} aria-hidden="true"/>
            {name}
        </a>
    )
}
