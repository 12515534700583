import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { ChevronLeftIcon, ChevronRightIcon, EyeIcon, TrashIcon } from '@heroicons/react/outline'
import LayoutComponent from '../../components/Layout/LayoutComponent'
import TableComponent from '../../components/TableComponent/TableComponent'
import SalesWidgetComponent from '../../components/SalesComponent/SalesWidgetComponent'
import NumberFormat from 'react-number-format'

export default function SalesPage() {

    const urlParams = new URLSearchParams(window.location.search);
    const pagina = urlParams.get('pagina') ?? 1
    const [products, setProducts] = useState([])
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(pagina)
    const [query, setQuery] = useState('')
    const [deleted, setDeleted] = useState(0)

    const notifyError = (message) => toast.error(message)
    const notifySuccess = (message) => toast.info(message)

    const headers = [
        { header: "#ID", width: "w-1/12" },
        { header: "Fecha de compra" }, 
        { header: "Última actulizacion" }, 
        { header: "Precio" },
        { header: "Estado Pago" },
        { header: "Estado orden" },
        { header: "Acciones" }
    ]

    const setActiveTab = (tab) => {
        const nonSelectedStyles = "inline-block mr-2 bg-gray-300 text-gray-500 hover:text-medicina-verde-500 shadow-inner text-sm px-3 py-1 rounded-xl uppercase font-semibold"
        const selectedStyles = "inline-block mr-2 bg-medicina-verde-500 text-white text-sm px-3 py-1 rounded-xl uppercase font-bold"
        if (tab === urlParams.get('origen')) return selectedStyles
        return nonSelectedStyles
    }

    const changePage = (direction) => {
        setLoading(true)
        if (direction === 'next') {
            setCurrentPage(parseInt(currentPage) + 1)
        } else {
            setCurrentPage(parseInt(currentPage) - 1)
        }
    }

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const origen = urlParams.get('origen')
        const pagina = urlParams.get('pagina') ?? 1
        if (pagina !== currentPage) window.history.pushState(null, null, `?origen=${origen}&pagina=${currentPage}`)
        const origenes = ['variantes', 'masters', 'deleted']
        const url = ` ${process.env.REACT_APP_API}/sales?page=${currentPage}`
        fetch(url)
            .then(response => {
                if (!response.ok) throw new Error('No se encontró el recurso solicitado.')
                return response.json()
            })
            .then(data => {
                setProducts(data)
                console.log("products?", data)
                setLoading(false)
            })
            .catch(error => notifyError(error.message === 'Failed to fetch' ? 'Problemas de conexión al backend' : error.message))
    }, [currentPage, query, deleted])

    const eliminarVenta = (saleId) => {
        const url = `${process.env.REACT_APP_API}/sales/${saleId}/delete`
        fetch(url)
            .then(data => data.json())
            .then(res => {
                console.log(res)
                window.location.reload()
            })
    }

    return (
        <LayoutComponent sectionTitle="Ventas en linea" section="sales">
            {products.data &&
                <>
                    <div className="mb-8">
                        <SalesWidgetComponent total={products.meta.total} />
                    </div>

                    <div className={`transition-all ${loading && 'filter blur-lg'}`}>
                        <TableComponent headers={headers}>
                            {products.data.map((product) => (
                                <tr key={'sales-row-' + product.id}>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{product.id}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 ">{product.created_at}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 ">{product.updated_at}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 font-semibold">
                                        <NumberFormat value={product.amount} displayType={'text'} thousandSeparator={'.'} decimalSeparator={false} prefix={'$'} />
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                        {!product.webpay && <span className="block w-full text-center font-semibold bg-red-500 text-white text-xs px-2 py-1 rounded-full">No pagado</span>}
                                        { product.webpay && product.webpay.status === "AUTHORIZED" && <span className="block w-full text-center font-semibold bg-green-500 text-white text-xs px-2 py-1 rounded-full">Pago exitoso</span>}
                                        
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                        { product.raw_state === 0 && <span className="inline-block w-full text-center  bg-gray-200 rounded-full px-3 py-1 text-xs font-semibold text-gray-700 mr-2">
                                            { product.refund 
                                                ? 'Reembolsado' 
                                                : product.invoice 
                                                    ? 'pendiente entrega' 
                                                    : product.state
                                            }
                                        </span> }
                                        { product.raw_state === 1 && <span className="inline-block w-full text-center  bg-green-300 rounded-full px-3 py-1 text-xs font-semibold text-green-700 mr-2">Orden Finalizada</span> }
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                        <div className='flex space-x-3'>
                                            <a href={`/sales/${product.id}`}><EyeIcon className="cursor-pointer text-gray-500 hover:text-medicina-verde-500 w-6 h-6" /></a>
                                           {product.raw_state === 0 &&  <TrashIcon onClick={ () => eliminarVenta(product.id) } className="cursor-pointer text-gray-500 hover:text-red-500 w-6 h-6" />}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </TableComponent>

                        <div className="w-full flex justify-center items-center space-x-2 py-8">
                            <button className="rounded p-4  bg-white shadow" onClick={() => currentPage > 1 && changePage('previous')}><ChevronLeftIcon className="w-6 h-6" /></button>
                            <button disabled className="rounded p-4 shadow-inner bg-gray-200 text-gray-600 font-black">{products.meta.current_page}</button>
                            <span className="px-2">de {products.meta.last_page}</span>
                            <button className="outline-none rounded p-4 bg-white shadow" onClick={() => currentPage < parseInt(products.meta.last_page) && changePage('next')}>Siguiente <ChevronRightIcon className="w-6 h-6 inline-block" /></button>
                        </div>
                    </div>
                </>}
        </LayoutComponent>
    )
}
