import { useCallback, useContext } from 'react'
import { useDropzone } from 'react-dropzone'
import aws from "aws-sdk"

export default function FileUpload({ path, maxFiles, productId, resource, setImage }) {

    var uniqueFilename = require('unique-filename')

    const s3 = new aws.S3({
        endpoint: process.env.REACT_APP_SPACES_ENDPOINT,
        accessKeyId: process.env.REACT_APP_SPACES_KEY,
        secretAccessKey: process.env.REACT_APP_SPACES_SECRET,
        region: 'nyc3'
    })

    const onDrop = useCallback(acceptedFiles => {
        const name = uniqueFilename(path + '/')
        console.log("name", name)
        acceptedFiles.forEach(async (file) => {

            let ext = file.name.split('.')
            ext = ext[ext.length - 1]
            const filename = name + '.' + ext
            console.log("filename", filename)
            s3.upload(
                {
                    Bucket: process.env.REACT_APP_SPACES_BUCKET,
                    ACL: "public-read",
                    Key: filename,
                    Body: file,
                },
                {
                    partSize: 10 * 1024 * 1024,
                    queueSize: 10,
                }

            ).send((err, data) => {

                if (err) return
                const payload = {
                    filename: filename,
                    mime: ext
                }
                setImage("https://cdn.lamedicina.cl/" + filename)
            
                const url = process.env.REACT_APP_API + '/attachment'
                fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(payload) })
                    .then(res => res.json())
                    .then(data => {
                        console.log("attachment response", data)
                        const relationship = {
                            attachment_id: data.data.insert_id,
                            product_id: productId
                        }
                        fetch(process.env.REACT_APP_API + '/attachment-' + resource, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(relationship) })
                            .then(res => {
                                return true;
                            })
                            .catch(err => {
                                console.log("ERROR:")
                                console.error(err)
                            })
                    })

            });
        })

    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    return (
        <div className="bg-white border-dashed border-4 border-gray-300 hover:bg-gray-500 transition-colors cursor-pointer hover:text-gray-200 w-full text-center font-semibold text-gray-600 text-xs p-5 rounded" {...getRootProps()}>
            <input {...getInputProps()} />
            {
                isDragActive
                    ? <p className="font-black">Suelte aquí!</p>
                    : <p>Arrastre una imágen nueva aquí para reemplazarla.</p>
            }
        </div>
    )
}
